(function () {

    this.ToolbarLink = function (options) {
        var defaults = {
            icon: 'circle',
            text: 'Link',
            disabled: false,
            id: null,
            type: null,
            click: null,
            sublinks: []
        };

        if (arguments[0] && typeof arguments[0] === "object") {
            this.options = extendDefaults(defaults, arguments[0]);
        }
    };

    this.Toolbar = function (options) {
        var defaults = {
            parent: null,
            subToolbar: null,
            links: []
        };


        // Create options by extending defaults with the passed in arugments
        if (arguments[0] && typeof arguments[0] === "object") {
            this.options = extendDefaults(defaults, arguments[0]);
        } else {
            this.options = defaults;
        }

        this._subToolbar = this.options.subToolbar;
        this._links = [];
        for (var i = 0; i < this.options.links.length; i++) {
            addLink.call(this, this.options.links[i]);
        }
    };

    this.Toolbar.prototype.load = function (links) {
        for (var i = 0; i < links.length; i++) {
            addLink.call(this, links[i]);
        }
    };

    this.Toolbar.prototype.empty = function () {
        this.options.parent.empty();
    };

    this.Toolbar.prototype.hide = function () {
        this.options.parent.slideUp(200);
    };
    this.Toolbar.prototype.toggle = function () {
        this.options.parent.slideToggle(200);
    };
    this.Toolbar.prototype.show = function () {
        this.options.parent.slideDown(200);
    };

    function addLink(link) {
        this._links.push(link);
        var _this = this;
        if(link.options.type == "color"){
            var l = document.getElementById('colorItem');
                l.style.visibility = "";

        }else{
            var l = $('<button id="' + link.options.id + '" type="button" class="toolbar-btn" style="color: ' + link.options.color + '"' + (link.options.disabled ? "disabled" : "") + '>' +
                '<span class="' + link.options.icon + '"></span><BR> ' + link.options.text +
                '</button>');
        }

        if (link.options.click) {
            l.click(function () {
                link.options.click(this);

                if (link.options.sublinks.length > 0) {
                    handleParentClick.call(_this, link)
                }
            });
        } else if (link.options.sublinks.length > 0) {
            l.click(function () {
                handleParentClick.call(_this, link)
            })
        }
        this.options.parent.append(l);
    }

    function handleSubToolbarHide() {
        this._subToolbar.hide();
    }

    function handleParentClick(link) {
        if(link.options.text != "Background"){
            try{
                var l = document.getElementById('colorItem');
                l.style.visibility = "hidden";
                document.getElementsByTagName('body')[0].appendChild(l);
            }catch(e){}
        }
        if (!this._subToolbar) {
            return null;
        }

        linkLength = link.options.sublinks.length-1;
        thisLength = this._subToolbar._links.length-1;

        try{
            if(link.options.sublinks[linkLength].options.text == this._subToolbar._links[thisLength].options.text){
                this._subToolbar.toggle();
                return;
            }
        }catch(err){}


        this._subToolbar.empty();
        this._subToolbar.load(link.options.sublinks);
        this._subToolbar.show(this._subToolbar);

    }

    // Utility method to extend defaults with user options
    function extendDefaults(source, properties) {
        var property;
        for (property in properties) {
            if (properties.hasOwnProperty(property)) {
                source[property] = properties[property];
            }
        }
        return source;
    }
})();
