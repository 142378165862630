/**
 * fabric.menuitem.js
 */
(function () {
    'use strict';

    // Used for word-breaking line wraps in text boxes
    fabric.Textbox.prototype._wrapLine = function(ctx, text, lineIndex) {
        var lineWidth        = 0,
            lines            = [],
            line             = '',
            words            = text.split(' '),
            word             = '',
            letter           = '',
            offset           = 0,
            infix            = ' ',
            wordWidth        = 0,
            infixWidth       = 0,
            letterWidth      = 0,
            largestWordWidth = 0;

        for (var i = 0; i < words.length; i++) {
            word = words[i];
            wordWidth = this._measureText(ctx, word, lineIndex, offset);
            lineWidth += infixWidth;

            // Break Words if wordWidth is greater than textbox width
            if (this.breakWords && wordWidth > this.width) {
                line += infix;
                var wordLetters = word.split('');
                while (wordLetters.length) {
                    letterWidth = this._getWidthOfChar(ctx, wordLetters[0], lineIndex, offset);
                    if (lineWidth + letterWidth > this.width) {
                        lines.push(line);
                        line = '';
                        lineWidth = 0;
                    }
                    line += wordLetters.shift();
                    offset++;
                    lineWidth += letterWidth;
                }
                word = '';
            } else {
                lineWidth += wordWidth;
            }

            if (lineWidth >= this.width && line !== '') {
                lines.push(line);
                line = '';
                lineWidth = wordWidth;
            }

            if (line !== '' || i === 1) {
                line += infix;
            }
            line += word;
            offset += word.length;
            infixWidth = this._measureText(ctx, infix, lineIndex, offset);
            offset++;

            // keep track of largest word
            if (wordWidth > largestWordWidth && !this.breakWords) {
                largestWordWidth = wordWidth;
            }
        }

        i && lines.push(line);

        if (largestWordWidth > this.dynamicMinWidth) {
            this.dynamicMinWidth = largestWordWidth;
        }

        return lines;
    };
    fabric.CustomTextbox = fabric.util.createClass(fabric.Textbox, {
        type: "customTextbox",
        initialize: function (text, options) {
            var defaults = {};
            options = $.extend({}, defaults, options)
            this.options = options;
            this.callSuper('initialize', text, options);
            this.initBehavior()
        },
        toObject: function () {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                nameEL: this.options.nameEL,
                descriptionEL: this.options.descriptionEL,
                priceEL: this.options.priceEL
            });
        },
        setCoords: function () {
            var ctx = this.ctx;
            this.callSuper('setCoords');
        }
    });
    fabric.MenuItem = fabric.util.createClass(fabric.Group, {
        type: "menuItem",
        initialize: function (options) {
            var defaults = {
                top: 20,
                left: 20,
                dW: .75,
                padding: 0,
                lockUniScaling: true,
                lockScalingFlip: true,
                nameEL: {
                    top: 0,
                    padding: 0,
                    width: 220,
                    fontSize: 20,
                    text: "Test Item",
                    fontFamily: "Helvetica"
                },
                descriptionEL: {
                    top: 45,
                    padding: 0,
                    fontSize: 20,
                    originY: "center",
                    fontFamily: "Helvetica",
                    text: "Test Description",
                    width: this.width * .75 * this.scaleX
                },
                priceEL: {
                    padding: 0,
                    width: 50,
                    left: 300,
                    fontSize: 20,
                    text: "$0.00",
                    originX: "right",
                    textAlign: "right",
                    fontFamily: "Helvetica"
                }
            };

            options = $.extend({}, defaults, options);
            this.options = options;

            var nameFill = this.options.nameEL.fill;
            var nameEL = new fabric.Text(
                this.options.nameEL.text,
                this.options.nameEL
            );

            var descriptionFill = this.options.descriptionEL.fill;
            var descriptionEL = new fabric.Text(
                this.options.descriptionEL.text,
                this.options.descriptionEL
            );

            var priceFill = this.options.priceEL.fill;
            var priceEL = new fabric.Text(
                this.options.priceEL.text,
                this.options.priceEL
            );

            this.callSuper('initialize', [
                nameEL,
                descriptionEL,
                priceEL
            ], options);

            this.nameEL = nameEL;
            this.priceEL = priceEL;
            this.descriptionEL = descriptionEL;

            if (nameFill)
                this.nameEL.setFill(nameFill);
            if (descriptionFill)
                this.descriptionEL.setFill(descriptionFill);
            if (priceFill)
                this.priceEL.setFill(priceFill);
        },
        toObject: function () {
            return fabric.util.object.extend(this.callSuper('toObject'), {
                dW: this.descriptionEL.width / 310,
                nameEL: this.nameEL,
                descriptionEL: this.descriptionEL,
                priceEL: this.priceEL
            });
        }
    });
    fabric.CustomTextbox.fromObject = function (object, callback) {
        return new fabric.CustomTextbox(object.text, object);
    };
    fabric.MenuItem.fromObject = function (object, callback) {
        return new fabric.MenuItem(object);
    };
    fabric.MenuItem.prototype.clone = function () {
        // return new fabric.MenuItem({
        //     top:this.top,
        //     left:this.left,
        //     width:this.width,
        //     top:this.top,
        // })
    };
    fabric.MenuItem.prototype.setCustomText = function (text) {
        if (!text) {
            text = this.descriptionEL.text.replace(/\n/g, "");
        }
        var tb = new fabric.Textbox(text, {
            width: this.width * this.options.dW * this.scaleX,
            scaleX: this.scaleX,
            scaleY: this.scaleY,
            fill: this.descriptionEL.fill,
            fontFamily: this.descriptionEL.fontFamily,
            fontSize: Math.floor(this.descriptionEL.fontSize * this.width / 310),
            fontStyle: this.descriptionEL.fontStyle,
            fontWeight: this.descriptionEL.fontWeight,
            breakWords: true
        });
        var lines = tb._textLines;
        var t = "";
        for (var i = 0; i < lines.length; i++) {
            t += lines[i];
            if (i != (lines.length - 1))
                t += " \n"
        }

        this.descriptionEL.text = t;
        if (!t.trim()) {
            this.descriptionEL.top = 10;
            this.descriptionEL.height = 0;
        }
    };
})();
(function () {
    this.kulaboardCanvas = function (options) {

        var defaults = {
            undoStack: [],
            redoStack: [],
            state: "",
            undo : '#undo',
            redo : '#redo',
            ctx: null,
            fonts: [],
            grid: false,
            grid_size: 20,
            toolset: null,
            toolbar: null
        };

        // Create options by extending defaults with the passed in arugments
        if (arguments[0] && typeof arguments[0] === "object") {
            this.options = extendDefaults(defaults, arguments[0]);
        }

        var _this = this;
        var stuff = this.options.ctx;
        this.ctx = this.options.ctx;
        this.fonts = this.options.fonts;
        this.toolset = this.options.toolset;
        this.redoStack = this.options.redoStack;
        this.undoStack = this.options.undoStack;

        //Set the starting gridsize to 1
        this.options.gridSize = this.options.grid ? this.options.grid_size : 1;

        /*this.ctx.on('click', function () {
            console.log("helloooooooooooooooooo");
        });
        */this.ctx.on('mouse:down', function () {
            closeAllWindows();
            _this.save();
        });

        this.ctx.on('object:moving', function (options) {
            //Snap to closest gridpoint
            options.target.set({
                left: Math.round(options.target.left / _this.options.gridSize) * _this.options.gridSize,
                top: Math.round(options.target.top / _this.options.gridSize) * _this.options.gridSize
            });
        });

        $(document).on('keydown', function (e) {
            var obj = _this.ctx.getActiveObject();
            var groupObj = _this.ctx.getActiveGroup();
            if (obj) {
                switch (e.keyCode) {
                    case 38:
                        //e.preventDefault();
                        if(document.activeElement.type != "text" && document.activeElement.nodeName != "TEXTAREA") {
                            obj.top -= 10;
                        }
                        break;
                    case 40:
                        //e.preventDefault();
                        if(document.activeElement.type != "text" && document.activeElement.nodeName != "TEXTAREA") {
                            obj.top += 10;
                        }
                        break;
                    case 37:
                        if(document.activeElement.type != "text" && document.activeElement.nodeName != "TEXTAREA"){
                            obj.left -= 10;
                        }

                        break;
                    case 39:
                        if(document.activeElement.type != "text" && document.activeElement.nodeName != "TEXTAREA") {
                            obj.left += 10;
                        }
                        break;
                    case 46:
                        var element = _this.ctx.getActiveObject();

                        if (element) {
                            _this.toolset.empty();
                            _this.ctx.remove(element);
                            _this.save();
                        }
                        break;
                    default:
                        break;
                }
                _this.ctx.renderAll();
            }else if(groupObj){
                switch (e.keyCode) {
                    case 46:
                        var group = _this.ctx.getActiveGroup();
                        if (group) {
                            group.forEachObject(function (child) {
                                _this.toolset.empty();
                                try{
                                    _this.ctx.remove(child);
                                }catch(err){                    }
                            }, _this);

                            _this.ctx.discardActiveGroup().renderAll();
                            _this.save();
                        }
                        break;
                    }
            }
        });

        this.ctx.on('object:selected', function (options) {
            _this.editActiveElement(options);
        });
        this.ctx.on('selection:created', function (options) {
            _this.editActiveElement(options);
        });
        this.emptyToolset();
        this.ctx.on('selection:cleared', function (options) {
            _this.emptyToolset();
        });
    };

    //Public Methods
    kulaboardCanvas.prototype.save = function () {
        var state = JSON.stringify(this.ctx.toJSON());

        if (state == this.undoStack[this.undoStack.length - 1]) {
            return;
        } else {
            if (this.undoStack.length > 10) {
                this.undoStack.splice(0, this.undoStack.length - 10);
            }

            this.redoStack = [];
            $('#redo').prop('disabled', true);

            // initial call won't have a state
            if (this.state) {
                this.undoStack.push(this.state);
                $('#undo').prop('disabled', false);
            }

            this.state = state;
        }
    };

    kulaboardCanvas.prototype.undo = function () {
        var saveStack = this.redoStack;
        var playStack = this.undoStack;
        var buttonsOn = "#undo";
        var buttonsOff = "#redo";
        if (playStack.length > 0){
            this.replay(playStack, saveStack, buttonsOn, buttonsOff);
        }

    };
    kulaboardCanvas.prototype.redo = function () {
        var saveStack = this.undoStack;
        var playStack = this.redoStack;
        var buttonsOn = "#redo";
        var buttonsOff = "#undo";
        if (playStack.length > 0)
            this.replay(playStack, saveStack, buttonsOn, buttonsOff);
    };

    kulaboardCanvas.prototype.replay = function (playStack, saveStack, buttonsOn, buttonsOff) {
        var _this = this;

        saveStack.push(this.state);
        this.state = playStack.pop();
        var on = $(buttonsOn);
        var off = $(buttonsOff);

        // turn both buttons off for the moment to prevent rapid clicking
        on.prop('disabled', true);
        off.prop('disabled', true);

        _this.ctx.clear();
        _this.ctx.loadFromJSON(this.state, function() {
            _this.ctx.renderAll();

            if (playStack.length) {
                on.prop('disabled', false);
            }
            if (saveStack.length) {
                off.prop('disabled', false);
            }
        });
    };

    kulaboardCanvas.prototype.render = function () {
        var _this = this;
        _this.ctx.renderAll();
    };

    kulaboardCanvas.prototype.emptyToolset = function () {
        var _this = this;
        _this.toolset.empty();
        _this.toolset.append($("<h3>No Object Selected</h3>"));
    };

    kulaboardCanvas.prototype.isEmpty = function () {
        var canvasElement = this.ctx;
        return canvasElement._objects.length == 0 && !canvasElement.backgroundImage;
    };

    kulaboardCanvas.prototype.toggleGridColor = function(){
        var _this = this;
        var grid = this.options.grid;
        this.options.grid = grid;

        if(typeof this.options.gridcolor != 'undefined'){
            if (this.options.gridcolor == "white") {
                if (this.options.gridSize == 9) {
                    gridImgPath = '/img/whitegridxSmall.png';
                }
                else if (this.options.gridSize == 15) {
                    gridImgPath = '/img/whitegridSmall.png';
                }
                else if (this.options.gridSize == 20) {
                    gridImgPath = '/img/whitegridmed.png';
                }
                else if (this.options.gridSize == 27.5) {
                    gridImgPath = '/img/whitegridBig.png';
                }
            } else {
                if (this.options.gridSize == 9) {
                    gridImgPath = '/img/gridxSmall.png';
                }
                else if (this.options.gridSize == 15) {
                    gridImgPath = '/img/gridSmall.png';
                }
                else if (this.options.gridSize == 20) {
                    gridImgPath = '/img/gridmed.png';
                }
                else if (this.options.gridSize == 27.5) {
                    gridImgPath = '/img/gridBig.png';
                }

            }
        }else{
            if(this.options.gridSize == 9){gridImgPath = '/img/gridxSmall.png';}
            else if(this.options.gridSize == 15){gridImgPath = '/img/gridSmall.png';}
            else if(this.options.gridSize == 20){gridImgPath = '/img/gridmed.png';}
            else if(this.options.gridSize == 27.5){gridImgPath = '/img/gridBig.png';}
        }

        if(grid){
            _this.ctx.setOverlayImage(gridImgPath,
                function () {
                    _this.ctx.overlayImage.height += 5;
                    _this.ctx.overlayImage.width += 20;
                    _this.ctx.renderAll();
                }, {
                    opacity: .65,
                    overlayImageStretch: false
                }
            );
        }
    },

    kulaboardCanvas.prototype.toggleGrid = function () {
        var _this = this;
        var grid = !this.options.grid;
        this.options.gridSize = grid ? this.options.grid_size : 1;
        this.options.grid = grid;
        var gridImgPath = '/img/gridSmall.png';

        if(typeof this.options.gridcolor != 'undefined'){
            if (this.options.gridcolor == "white") {
                if (this.options.gridSize == 9) {
                    gridImgPath = '/img/whitegridxSmall.png';
                }
                else if (this.options.gridSize == 15) {
                    gridImgPath = '/img/whitegridSmall.png';
                }
                else if (this.options.gridSize == 20) {
                    gridImgPath = '/img/whitegridmed.png';
                }
                else if (this.options.gridSize == 27.5) {
                    gridImgPath = '/img/whitegridBig.png';
                }
            } else {
                if (this.options.gridSize == 9) {
                    gridImgPath = '/img/gridxSmall.png';
                }
                else if (this.options.gridSize == 15) {
                    gridImgPath = '/img/gridSmall.png';
                }
                else if (this.options.gridSize == 20) {
                    gridImgPath = '/img/gridmed.png';
                }
                else if (this.options.gridSize == 27.5) {
                    gridImgPath = '/img/gridBig.png';
                }

            }
        }else{
            if(this.options.gridSize == 9){gridImgPath = '/img/gridxSmall.png';}
            else if(this.options.gridSize == 15){gridImgPath = '/img/gridSmall.png';}
            else if(this.options.gridSize == 20){gridImgPath = '/img/gridmed.png';}
            else if(this.options.gridSize == 27.5){gridImgPath = '/img/gridBig.png';}
        }



        if (grid) {
            _this.ctx.setOverlayImage(gridImgPath,


                function () {
                    _this.ctx.overlayImage.height += 5;
                    _this.ctx.overlayImage.width += 20;
                    _this.ctx.renderAll();
                }, {
                    opacity: .65,
                    overlayImageStretch: false
                }
            );
        } else {
            _this.ctx.setOverlayImage(null, function () {
                _this.ctx.renderAll();
            });
        }
    };

    //Public Methods
    kulaboardCanvas.prototype.deleteActiveElement = function (event) {
        var element = this.ctx.getActiveObject();
        var group = this.ctx.getActiveGroup();
        if (element) {
            this.toolset.empty();
            this.ctx.remove(element);
            this.save();
        } else if (group) {
                group.forEachObject(function (child) {
                    this.toolset.empty();
                    try{
                        this.ctx.remove(child);
                    }catch(err){                    }
                }, this);
                this.ctx.discardActiveGroup().renderAll();
                this.save();
        }
    };
    kulaboardCanvas.prototype.groupSelect = function () {
    };
    //Runs when an element is double clicked.
    kulaboardCanvas.prototype.editActiveElement = function (event) {
        if (event.target) {
            switch (event.target.type) {
                case 'image':
                    _editImage.call(this, event);
                    break;
                case 'textbox':
                    _editTextBox.call(this, event);
                    break;
                case 'group':
                    _editGroup.call(this, event);
                    break;
                case 'rect':
                    _editRect.call(this, event);
                    break;
                case 'circle':
                    _editCircle.call(this, event);
                    break;
                case 'line':
                    _editLine.call(this, event);
                    break;
                case 'menuItem':
                    _editItem.call(this, event);
                    break;
                default:
                    break;
            }
        }
    };

    kulaboardCanvas.prototype.addItem = function (url) {
        var _this = this;
        var item = new fabric.MenuItem({
            name: "Test Item",
            description: "test description",
            price: "$1.99"
        });
        _this.ctx.add(item);
    };
    kulaboardCanvas.prototype.addImage = function (url) {
        var _this = this;
        fabric.Image.fromURL(url, function (img) {
            while(img.width >300 || img.height>300){
                img.width = img.width/1.25;
                img.height = img.height/1.25;
            }
            _this.ctx.add(img);
        });
    };
    kulaboardCanvas.prototype.importBoard = function (url) {
        var _this = this;
        var id = window.location.href.split("/");
            id = id[id.length-1];
        var ext = url.split('.');
        ext =ext[ext.length-1];
        if(ext != "zip"){
            window.alert('Not a zip file');

        }else{
            $.ajax({
                type: 'POST',
                url: '/boardmanager/import',
                data: {
                    url:  url,
                    id: id,
                },
                success:function (data) {
                    var json = data.split("<!DOCTYPE html>");
                    json = json[0];

                    _this.ctx.loadFromJSON(json,  function() {
                        _this.ctx.renderAll();
                        document.getElementById("saveButton").click();
                    });


                }
            });
           /* var msg = $.ajax({type: "GET", url: url, async: false}).responseText;*/
        }
    };
    kulaboardCanvas.prototype.getBackgroundColor = function(){
        var _this = this;
        return this.ctx.backgroundColor;
    }
    kulaboardCanvas.prototype.getBackground = function(){
        var _this = this;
        if(this.ctx.backgoundImage != null || typeof this.ctx.backgroundImage == "undefined" || this.ctx.backgroundImage == ""){
            return false;
        }else{
            return true;
        }
    }
    kulaboardCanvas.prototype.colorBG = function(color){
        var _this = this;
        this.ctx.setBackgroundImage(fabric.Image.fromURL(""), function () {
            _this.ctx.renderAll();
        });
        this.ctx.setBackgroundColor(color, function () {
            _this.ctx.renderAll();
        });
    }
    kulaboardCanvas.prototype.addBg = function (url) {
        var _this = this;
        if(url == ""){
            this.ctx.setBackgroundColor("rgb(255,255,255)", function () {
                _this.ctx.renderAll();
            });
            this.ctx.setBackgroundImage(fabric.Image.fromURL(url), function () {
                _this.ctx.renderAll();
            });
        }else{
            _this.ctx.setBackgroundImage(url,
                function () {
                    _this.ctx.backgroundImage.height = _this.ctx.getHeight();
                    _this.ctx.backgroundImage.width = _this.ctx.getWidth();
                    _this.ctx.renderAll();
                }, {
                    backgroundImageOpacity: 1,
                    backgroundImageStretch: true
                }
            );
            this.ctx.setBackgroundImage(fabric.Image.fromURL(url), function () {
                _this.ctx.renderAll();
            });
        }




    };

    //Shape
    kulaboardCanvas.prototype.addShape = function (shape) {
        var _this = this;
        var t;
        switch (shape) {
            case 'square':
                t = new fabric.Rect({fill: "black", height: 30, width: 30, padding: 0});
                break;
            case 'circle':
                t = new fabric.Circle({fill: "black", radius: 30});
                break;
            case 'line':
                t = new fabric.Line([10, 10, 100, 10], {stroke: "black", strokeWidth: 5});
                break;
            default:
                break;
        }
        _this.ctx.add(t);
    };
    //Text functions
    kulaboardCanvas.prototype.addText = function () {
        var _this = this;

        var t = new fabric.Textbox("Text", {
            fontSize: 26,
            fontFamily: "helvetica",
            textAlign: "left"
        });
        _this.ctx.add(t);
    };
/*    kulaboardCanvas.prototype.addText = function () {
        var _this = this;

        var t = new fabric.IText("Text", {
            fontSize: 26,
            fontFamily: "helvetica",
            textAlign: "left"
        });
        _this.ctx.add(t);
    };*/

    kulaboardCanvas.prototype.removeText = function (text) {
        this.ctx.remove(text);
    };

    //Private Methods

    // Utility method to extend defaults with user options
    function extendDefaults(source, properties) {
        var property;
        for (property in properties) {
            if (properties.hasOwnProperty(property)) {
                source[property] = properties[property];
            }
        }
        return source;
    }
    function _clone(element) {
        var _this = this;

        if (element.type == 'menuItem') {

            var clonedgroup = new fabric.MenuItem();

            //Remove default objects
            clonedgroup._objects = [];

            //Add name, description, and price from menu item being cloned
            var nameEL = fabric.util.object.clone(element.nameEL);
            clonedgroup.nameEL = nameEL;
            clonedgroup.addWithUpdate(nameEL);
            var descEL = fabric.util.object.clone(element.descriptionEL);
            clonedgroup.descriptionEL = descEL;
            clonedgroup.addWithUpdate(descEL);
            var priceEL = fabric.util.object.clone(element.priceEL);
            clonedgroup.priceEL = priceEL;
            clonedgroup.addWithUpdate(priceEL);


            //Move it over a bit, and add to the canvas
            clonedgroup.setLeft(element.getLeft() + 10);
            clonedgroup.setTop(element.getTop() + 10);
            _this.ctx.add(clonedgroup);
            _this.render();
        } else {
            element.clone(function (clone) {
                clone.setLeft(clone.getLeft() + 10);
                clone.setTop(clone.getTop() + 10);
                _this.ctx.add(clone);
            });
        }
    }

    var _inputFactory = {
        tabs: function (tabs) {
            var tabList = $("<ul class='nav nav-tabs' role='tablist' ></ul>")
            for (var i = 0; i < tabs.length; i++) {
                var t = $('<li><a href="' + tabs[i].link + '" data-toggle="tab">' + tabs[i].name + '</a></li>');
                if (i == 0)
                    t.addClass('active');

                tabList.append(t);
            }
            return tabList;
        },
        textAlign: function (t) {
            var _this = this;
            var formGroup = $(_templates.form.group);
            var l = $(_templates.form.left_align);
            var c = $(_templates.form.center_align);
            var r = $(_templates.form.right_align);
            formGroup.append(l, c, r);
            formGroup.children().on("click", function (e) {
                var align = $(this).data("align");
                t.setTextAlign(align);
                _this.render();
            });
            return formGroup;
        },
        multiTextAlign: function (t) {
            var _this = this;
            var formGroup = $(_templates.form.group);
            var l = $(_templates.form.left_align);
            var c = $(_templates.form.center_align);
            var r = $(_templates.form.right_align);
            formGroup.append(l, c, r);
            formGroup.children().on("click", function (e) {
                var align = $(this).data("align");
                for(i=0; i<t._objects.length; i++){
                    t._objects[i].setTextAlign(align);
                }

                _this.render();
            });
            return formGroup;
        },
        fontStyles: function (t) {
            var _this = this;
            var formGroup = $(_templates.form.group);
            var b = $(_templates.form.bold).css('font-weight', 'bold');
            var i = $(_templates.form.italic).css('font-style', 'italic');
            var s = $(_templates.form.strikethrough).css('text-decoration', 'line-through');
            var u = $(_templates.form.underline).css('text-decoration', 'underline');
            var o = $(_templates.form.overline).css('text-decoration', 'overline');
            formGroup.append(b, i, s, u, o);
            formGroup.children().on("click", function (e) {
                switch ($(this).val()) {
                    case 'B':
                        t.setFontWeight((t.getFontWeight() != "bold" ? "bold" : "normal"));
                        break;
                    case 'I':
                        t.setFontStyle((t.getFontStyle() != "italic" ? "italic" : "normal"));
                        break;
                    case 'S':
                        t.setTextDecoration((t.getTextDecoration() != "line-through" ? "line-through" : ""));
                        break;
                    case 'U':
                        t.setTextDecoration((t.getTextDecoration() != "underline" ? "underline" : ""));
                        break;
                    case 'O':
                        t.setTextDecoration((t.getTextDecoration() != "overline" ? "overline" : ""));
                        break;
                    default:
                        break;
                }
                _this.render();
            });
            return formGroup;
        },
        multiFontStyles: function (t) {
            var _this = this;
            var formGroup = $(_templates.form.group);
            var b = $(_templates.form.bold).css('font-weight', 'bold');
            var i = $(_templates.form.italic).css('font-style', 'italic');
            var s = $(_templates.form.strikethrough).css('text-decoration', 'line-through');
            var u = $(_templates.form.underline).css('text-decoration', 'underline');
            var o = $(_templates.form.overline).css('text-decoration', 'overline');
            formGroup.append(b, i, s, u, o);
            formGroup.children().on("click", function (e) {
                var check = false;
               if($(this).val() == 'B') {
                   for (i = 0; i < t._objects.length; i++) {
                       if (t._objects[i].getFontWeight() == 'normal') {
                           for (i = 0; i < t._objects.length; i++) {
                               t._objects[i].setFontWeight("bold");
                               check = true;
                           }
                           if (check == true) {
                               break;
                           }
                       }
                   }
                   if (check == false) {
                       for (i = 0; i < t._objects.length; i++) {
                           t._objects[i].setFontWeight("normal");
                       }
                   }
               }
               else if($(this).val() == 'I') {
                   for (i = 0; i < t._objects.length; i++) {
                       if (t._objects[i].getFontStyle() == 'normal'|| t._objects[i].getFontStyle() == '') {
                           for (i = 0; i < t._objects.length; i++) {
                               t._objects[i].setFontStyle("italic");
                               check = true;
                           }
                           if (check == true) {
                               break;
                           }
                       }
                   }
                   if (check == false) {
                       for (i = 0; i < t._objects.length; i++) {
                           t._objects[i].setFontStyle("normal");
                       }
                    }
               }
               else if($(this).val() == 'S') {
                   for (i = 0; i < t._objects.length; i++) {
                       if (t._objects[i].getTextDecoration() != 'line-through') {
                           for (i = 0; i < t._objects.length; i++) {
                               t._objects[i].setTextDecoration("line-through");
                               check = true;
                           }
                           if (check == true) {
                               break;
                           }
                       }
                   }
                   if (check == false) {
                       for (i = 0; i < t._objects.length; i++) {
                           t._objects[i].setTextDecoration("");
                       }
                   }
               }
               else if($(this).val() == 'U') {
                   for (i = 0; i < t._objects.length; i++) {
                       if (t._objects[i].getTextDecoration() != 'underline') {
                           for (i = 0; i < t._objects.length; i++) {
                               t._objects[i].setTextDecoration("underline");
                               check = true;
                           }
                           if (check == true) {
                               break;
                           }
                       }
                   }
                   if (check == false) {
                       for (i = 0; i < t._objects.length; i++) {
                           t._objects[i].setTextDecoration("");
                       }
                   }
               }
               else if($(this).val() == 'O') {
                   for (i = 0; i < t._objects.length; i++) {
                       if (t._objects[i].getTextDecoration() != 'overline') {
                           for (i = 0; i < t._objects.length; i++) {
                               t._objects[i].setTextDecoration("overline");
                               check = true;
                           }
                           if (check == true) {
                               break;
                           }
                       }
                   }
                   if (check == false) {
                       for (i = 0; i < t._objects.length; i++) {
                           t._objects[i].setTextDecoration("");
                       }
                   }
               }
                _this.render();
            });
            return formGroup;
        },
        multiMenuFontStyles: function (t, inString) {
            var _this = this;
            var formGroup = $(_templates.form.group);
            var b = $(_templates.form.bold).css('font-weight', 'bold');
            var i = $(_templates.form.italic).css('font-style', 'italic');
            var s = $(_templates.form.strikethrough).css('text-decoration', 'line-through');
            var u = $(_templates.form.underline).css('text-decoration', 'underline');
            var o = $(_templates.form.overline).css('text-decoration', 'overline');

            formGroup.append(b, i, s, u, o);
            formGroup.children().on("click", function (e) {
                var check = false;
                if(inString == 'description'){
                    if($(this).val() == 'B') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].descriptionEL.getFontWeight() == 'normal') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].descriptionEL.setFontWeight("bold");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].descriptionEL.setFontWeight("normal");
                            }
                        }
                    }

                    else if($(this).val() == 'I') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].descriptionEL.getFontStyle() == 'normal' || t._objects[i].descriptionEL.getFontStyle() == '') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].descriptionEL.setFontStyle("italic");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].descriptionEL.setFontStyle("normal");
                            }
                        }
                    }

                    else if($(this).val() == 'S') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].descriptionEL.getTextDecoration() != 'line-through') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].descriptionEL.setTextDecoration("line-through");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].descriptionEL.setTextDecoration("");
                            }
                        }
                    }
                    else if($(this).val() == 'U') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].descriptionEL.getTextDecoration() != 'underline') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].descriptionEL.setTextDecoration("underline");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].descriptionEL.setTextDecoration("");
                            }
                        }
                    }
                    else if($(this).val() == 'O') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].descriptionEL.getTextDecoration() != 'overline') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].descriptionEL.setTextDecoration("overline");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].descriptionEL.setTextDecoration("");
                            }
                        }
                    }
                }
                else if(inString == 'name'){
                    if($(this).val() == 'B') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].nameEL.getFontWeight() == 'normal') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].nameEL.setFontWeight("bold");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].nameEL.setFontWeight("normal");
                            }
                        }
                    }

                    else if($(this).val() == 'I') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].nameEL.getFontStyle() == 'normal' || t._objects[i].nameEL.getFontStyle() == '') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].nameEL.setFontStyle("italic");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].nameEL.setFontStyle("normal");
                            }
                        }
                    }

                    else if($(this).val() == 'S') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].nameEL.getTextDecoration() != 'line-through') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].nameEL.setTextDecoration("line-through");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].nameEL.setTextDecoration("");
                            }
                        }
                    }
                    else if($(this).val() == 'U') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].nameEL.getTextDecoration() != 'underline') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].nameEL.setTextDecoration("underline");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].nameEL.setTextDecoration("");
                            }
                        }
                    }
                    else if($(this).val() == 'O') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].nameEL.getTextDecoration() != 'overline') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].nameEL.setTextDecoration("overline");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].nameEL.setTextDecoration("");
                            }
                        }
                    }
                }
                else if(inString == 'price'){
                    if($(this).val() == 'B') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].priceEL.getFontWeight() == 'normal') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].priceEL.setFontWeight("bold");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].priceEL.setFontWeight("normal");
                            }
                        }
                    }

                    else if($(this).val() == 'I') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].priceEL.getFontStyle() == 'normal'|| t._objects[i].priceEL.getFontStyle() == '') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].priceEL.setFontStyle("italic");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].priceEL.setFontStyle("normal");
                            }
                        }
                    }

                    else if($(this).val() == 'S') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].priceEL.getTextDecoration() != 'line-through') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].priceEL.setTextDecoration("line-through");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].priceEL.setTextDecoration("");
                            }
                        }
                    }
                    else if($(this).val() == 'U') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].priceEL.getTextDecoration() != 'underline') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].priceEL.setTextDecoration("underline");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].priceEL.setTextDecoration("");
                            }
                        }
                    }
                    else if($(this).val() == 'O') {
                        for (i = 0; i < t._objects.length; i++) {
                            if (t._objects[i].priceEL.getTextDecoration() != 'overline') {
                                for (i = 0; i < t._objects.length; i++) {
                                    t._objects[i].priceEL.setTextDecoration("overline");
                                    check = true;
                                }
                                if (check == true) {
                                    break;
                                }
                            }
                        }
                        if (check == false) {
                            for (i = 0; i < t._objects.length; i++) {
                                t._objects[i].priceEL.setTextDecoration("");
                            }
                        }
                    }
                }
                _this.render();
            });
            return formGroup;
        },

        ValignAll: function (groupObjects){
            var _this = this;
            return _buildInput({
                input: _templates.image_inputs.talign,
                value: null,
                listener: {
                    action: 'click',
                    f: function () {
                        var smallest = groupObjects[0].top;
                        for(i=0; i< groupObjects.length; i++){
                            if(groupObjects[i].top < smallest){
                                smallest = groupObjects[i].top;
                            }
                        }
                        for(i=0; i< groupObjects.length; i++){
                            groupObjects[i].top = smallest;
                        }
                        _this.render();
                    }
                }
            });
        },
        HalignAll: function (groupObjects){
            var _this = this;
            return _buildInput({
                input: _templates.image_inputs.lalign,
                value: null,
                listener: {
                    action: 'click',
                    f: function () {
                        var smallest = groupObjects[0].left;
                        for(i=0; i< groupObjects.length; i++){
                            if(groupObjects[i].left < smallest){
                                smallest = groupObjects[i].left;
                            }
                        }
                        for(i=0; i< groupObjects.length; i++){
                            groupObjects[i].left = smallest;
                        }
                        _this.render();
                    }
                }
            });
        },

        addBorder: function (t, value, onchange) {
            var _this = this;
            var formGroup1 = $(_templates.form.group);
            var formGroup2= $(_templates.form.group);
            var btnGroup1 = $(_templates.form.btnGroup);
            var column1 = $(_templates.form.column);
            var column2 = $(_templates.form.column);
            var column3 = $(_templates.form.column);
            var column4 = $(_templates.form.column);
            try{var test = t.strokeDashArray[0]}catch(err){t.strokeDashArray = [0,0];}
            try{var test = t.strokeDashArray[1]}catch(err){t.strokeDashArray =[0,0];}


            //border width
            var bw = _buildInput({
                input: _templates.text_inputs.bordernumber,
                label: "Border Width",
                value: t.strokeWidth*5,
            });
            //line length
            var ll = _buildInput({
                input: _templates.text_inputs.bordernumber,
                label: "Dash Line Length",
                value: t.strokeDashArray[0]*10,
            });
            //space length
            var sl = _buildInput({
                input: _templates.text_inputs.bordernumber,
                label: "Dash Space length",
                value: t.strokeDashArray[1]*10,
            });
            //color
            var bc = _buildInput({
                input: _templates.text_inputs.text,
                label: "Border Color"
            });
            var input = bc.find('input').get(0);
            var bwInput = bw.find('input').get(0);
            var lInput = ll.find('input').get(0);
            var sInput = sl.find('input').get(0);





            //solid border
            var sb =  _buildInput({
                input: _templates.shapes.solidborder,
                value: null,
                listener: {
                    action: 'click',
                    f: function () {

                        if(t.borderSet == true && t.strokeDashArray !=0){
                            t.strokeDashArray = 0;
                            formGroup2.children('div').remove();
                            formGroup2.append(bc, bw);

                        }
                        else if(t.borderSet == false || typeof t.borderSet == 'undefined'){
                            t.borderSet = true;
                            t.strokeDashArray = 0;
                            t.padding = 5;
                            t.strokeWidth = 1;
                            if(typeof t.stroke == 'undefined' || t.stroke == null){t.stroke = '#FF0000';}else{}
                            bc.find('input').get(0).value = t.stroke;
                            bw.find('input').get(0).value = t.strokeWidth;
                            formGroup2.append(bc, bw);
                        }
                        else{
                            t.borderSet = false;
                            t.padding = 0;
                            t.strokeWidth = 0;
                            formGroup2.children('div').remove();
                        }
                        _this.render();
                    }
                }
            });

            //dashed border
            var db =  _buildInput({
                input: _templates.shapes.dashedborder,
                value: null,
                listener: {
                    action: 'click',
                    f: function () {
                        if(t.borderSet == true && (typeof t.strokeDashArray == 'undefined' || t.strokeDashArray ==0)){
                            t.strokeDashArray = [4,2];
                            formGroup2.children('div').remove();
                            column3.append(bc, bw);
                            column4.append(ll, sl);
                            formGroup2.append(column3, column4);
                        }
                        else if(t.borderSet == false || typeof t.borderSet == 'undefined'){
                            t.borderSet = true;
                            t.strokeDashArray = [4,2];
                            t.padding = 5;
                            t.strokeWidth = 1;
                            if(typeof t.stroke == 'undefined' || t.stroke == null){t.stroke = '#FF0000';}else{}
                            bc.find('input').get(0).value = t.stroke;
                            bw.find('input').get(0).value = t.strokeWidth;
                            ll.find('input').get(0).value = t.strokeDashArray[0]*10;
                            sl.find('input').get(0).value = t.strokeDashArray[1]*10;
                            column3.append(bc, bw);
                            column4.append(ll, sl);
                            formGroup2.append(column3, column4);
                            //if(typeof t.strokeDashArray == 'undefined'){t.strokeDashArray = [4,2];}
                            //if(typeof t.padding == 'undefined'){t.padding = 5;}
                            //if(typeof t.strokeWidth == 'undefined'){t.strokeWidth = 1;}
                        }
                        else{
                            t.borderSet = false;
                            t.padding = 0;
                            t.strokeWidth = 0;
                            formGroup2.children('div').remove();
                        }
                        _this.render();
                    }
                }
            });
            var c = new jscolor(input);

            if (value) {
                c.fromString(value);
            } else {

                if(typeof t.stroke == null){
                    c.fromString("#000000");
                }else{
                    c.fromString('#' +t.stroke);
                }

            }
            if (onchange) {
                input.onchange = onchange;
            } else {
                bwInput.onchange = function(){
                    t.strokeWidth = parseFloat(this.value/5);
                    _this.render();
                };
                input.onchange = function () {
                    t.setStroke("#" + this.value);
                    _this.render();
                };
                lInput.onchange = function(){
                    try{t.strokeDashArray[0] = parseFloat(this.value/10);}catch(err){};
                    _this.render();
                }
                sInput.onchange = function(){
                    try{t.strokeDashArray[1] = parseFloat(this.value/10);}catch(err){};
                    _this.render();
                };
            }
            //end color

            if(t.borderSet == true && t.strokeDashArray !=0){
                formGroup2.children('div').remove();
                column3.append(bc, bw);
                column4.append(ll, sl);
                formGroup2.append(column3, column4);
            }
            if(t.borderSet == true && (typeof t.strokeDashArray == 'undefined' || t.strokeDashArray ==0)){
                formGroup2.children('div').remove();
                formGroup2.append(bc, bw);
            }

            if(t.type == "textbox"){
                formGroup1.append(sb);
                column1.append(formGroup1);
                column2.append(formGroup2);
                btnGroup1.append(column1, column2);
            }else{
                formGroup1.append(sb,db);
                column1.append(formGroup1);
                column2.append(formGroup2);
                btnGroup1.append(column1, formGroup2);
            }

            return btnGroup1;
        },
        addLineBorder: function (t, value, onchange) {
            var _this = this;
            var formGroup1 = $(_templates.form.group);
            var formGroup2= $(_templates.form.group);
            var btnGroup1 = $(_templates.form.btnGroup);
            var column1 = $(_templates.form.column);
            var column2 = $(_templates.form.column);
            var column3 = $(_templates.form.column);
            var column4 = $(_templates.form.column);
            try{var test = t.strokeDashArray[0]}catch(err){t.strokeDashArray = [0,0];}
            try{var test = t.strokeDashArray[1]}catch(err){t.strokeDashArray =[0,0];}


            //border width
            var bw = _buildInput({
                input: _templates.text_inputs.bordernumber,
                label: "Border Width",
                value: t.strokeWidth*5,
            });
            //line length
            var ll = _buildInput({
                input: _templates.text_inputs.bordernumber,
                label: "Dash Line Length",
                value: t.strokeDashArray[0]*10,
            });
            //space length
            var sl = _buildInput({
                input: _templates.text_inputs.bordernumber,
                label: "Dash Space length",
                value: t.strokeDashArray[1]*10,
            });
            //color

            var bwInput = bw.find('input').get(0);
            var lInput = ll.find('input').get(0);
            var sInput = sl.find('input').get(0);






            //dashed border
            var db =  _buildInput({
                input: _templates.shapes.dashedborder,
                value: null,
                listener: {
                    action: 'click',
                    f: function () {
                        if(t.borderSet == true && (typeof t.strokeDashArray == 'undefined' || t.strokeDashArray ==0)){
                            t.strokeDashArray = [4,2];
                            formGroup2.children('div').remove();
                            column3.append(bw);
                            column4.append(ll, sl);
                            formGroup2.append(column3, column4);
                        }
                        else if(t.borderSet == false || typeof t.borderSet == 'undefined'){
                            t.borderSet = true;
                            t.strokeDashArray = [4,2];
                            t.padding = 5;

                            if(typeof t.stroke == 'undefined' || t.stroke == null){t.stroke = '#FF0000';}else{}

                            bw.find('input').get(0).value = t.strokeWidth*5;
                            ll.find('input').get(0).value = t.strokeDashArray[0]*10;
                            sl.find('input').get(0).value = t.strokeDashArray[1]*10;
                            column3.append(bw);
                            column4.append(ll, sl);
                            formGroup2.append(column3, column4);
                            //if(typeof t.strokeDashArray == 'undefined'){t.strokeDashArray = [4,2];}
                            //if(typeof t.padding == 'undefined'){t.padding = 5;}
                            //if(typeof t.strokeWidth == 'undefined'){t.strokeWidth = 1;}
                        }
                        else{
                            t.borderSet = false;
                            t.strokeDashArray = [0,0];
                            t.padding = 0;
                            formGroup2.children('div').remove();
                        }
                        _this.render();
                    }
                }
            });


            if (onchange) {
            } else {
                bwInput.onchange = function(){
                    t.strokeWidth = parseFloat(this.value/5);
                    _this.render();
                };
                lInput.onchange = function(){
                    try{t.strokeDashArray[0] = parseFloat(this.value/10);}catch(err){};
                    _this.render();
                }
                sInput.onchange = function(){
                    try{t.strokeDashArray[1] = parseFloat(this.value/10);}catch(err){};
                    _this.render();
                };
            }
            //end color

            if(t.borderSet == true && t.strokeDashArray !=0){
                formGroup2.children('div').remove();
                column3.append(bw);
                column4.append(ll, sl);
                formGroup2.append(column3, column4);
            }
            if(t.borderSet == true && (typeof t.strokeDashArray == 'undefined' || t.strokeDashArray ==0)){
                formGroup2.children('div').remove();
                formGroup2.append(bw);
            }

                formGroup1.append(db);
                column1.append(formGroup1);
                column2.append(formGroup2);
                btnGroup1.append(column1, column2);


            return btnGroup1;
        },
        fontFamily: function (t) {
            var _this = this;
            var formGroup = $(_templates.form.group);
            var parent = $(_templates.form.select);
            var label = $(_templates.form.label).text("Font Family");

            for (var i = 0; i < this.fonts.length; i++) {
                var f = $(_templates.form.option);
                f.val(this.fonts[i].font_name);
                f.text(this.fonts[i].font_family);
                if (this.fonts[i].font_family == t.getFontFamily()) {
                    f.attr('selected', 'selected');
                }
                parent.append(f);
            }
            formGroup.append(label, parent);
            parent.on("change", function (e) {
                t.setFontFamily(this.selectedOptions[0].innerHTML);
                _this.render();
            });
            return formGroup;
        },
        multiFontFamily: function (t) {
            var _this = this;
            var formGroup = $(_templates.form.group);
            var parent = $(_templates.form.select);
            var label = $(_templates.form.label).text("Font Family");

            for (var i = 0; i < this.fonts.length; i++) {
                var f = $(_templates.form.option);
                f.val(this.fonts[i].font_name);
                f.text(this.fonts[i].font_family);
                parent.append(f);
            }
            formGroup.append(label, parent);
            parent.on("change", function (e) {
                for(i=0; i<t._objects.length; i++){
                    t._objects[i].setFontFamily(this.selectedOptions[0].innerHTML);
                }
                _this.render();
            });
            return formGroup;
        },
        multiMenuFontFamily: function (t, value) {
            var _this = this;
            var formGroup = $(_templates.form.group);
            var parent = $(_templates.form.select);
            var label = $(_templates.form.label).text("Font Family");

            for (var i = 0; i < this.fonts.length; i++) {
                var f = $(_templates.form.option);
                f.val(this.fonts[i].font_name);
                f.text(this.fonts[i].font_family);
                parent.append(f);
            }
            formGroup.append(label, parent);
            parent.on("change", function (e) {
                for(i=0; i<t._objects.length; i++){
                    if(value == 'description'){
                        t._objects[i].descriptionEL.setFontFamily(this.selectedOptions[0].innerHTML);
                    }
                    else if(value == 'name'){
                        t._objects[i].nameEL.setFontFamily(this.selectedOptions[0].innerHTML);
                    }
                    else if(value == 'price'){
                        t._objects[i].priceEL.setFontFamily(this.selectedOptions[0].innerHTML);
                    }


                }
                _this.render();
            });
            return formGroup;
        },
        forward: function (t) {
            var _this = this;
            return _buildInput({
                input: _templates.image_inputs.forward,
                listener: {
                    action: 'click',
                    f: function () {
                        t.bringForward();
                        _this.render();
                    }
                }
            });
        },
        back: function (t) {
            var _this = this;
            return _buildInput({
                input: _templates.image_inputs.back,
                listener: {
                    action: 'click',
                    f: function () {
                        t.sendBackwards();
                        _this.render();
                    }
                }
            });
        },
        rotate: function (t) {
            var _this = this;
            return _buildInput({
                input: _templates.form.rotate,
                value: null,
                listener: {
                    action: 'click',
                    f: function () {
                        var angle = t.getAngle();
                        t.setAngle(angle + 90).setCoords();
                        _this.render();
                    }
                }
            });
        },
        rotateInPlace: function (t) {
            var _this = this;
            return _buildInput({
                input: _templates.form.rotateInPlace,
                value: null,
                listener: {
                    action: 'click',
                    f: function () {

                        singleObjects = _this.ctx._activeGroup._objects;
                        for(i =0; i<singleObjects.length; i++)
                        {
                            singleObjects[i].lockRotation = !singleObjects[i].lockRotation;
                            var angle = singleObjects[i].getAngle();
                            singleObjects[i].setAngle(angle + 90).setCoords();
                        }
                        _this.render();
                    }
                }
            });
        },
        equalHeights: function (t) {
            var _this = this;
            return _buildInput({
                input: _templates.form.equalHeight,
                value: null,
                listener: {
                    action: 'click',
                    f: function () {
                        var h = t._objects[0].height;
                        var scaleY = t._objects[0].scaleY;
                        t.forEachObject(function (obj) {
                            obj.height = h;
                            obj.scaleY = scaleY;
                        }, _this.ctx);
                        t.addWithUpdate(null);
                        _this.render();
                    }
                }
            });
        },
        equalWidths: function (t) {
            var _this = this;
            return _buildInput({
                input: _templates.form.equalWidth,
                value: null,
                listener: {
                    action: 'click',
                    f: function () {
                        var w = t._objects[0].width;
                        var scaleX = t._objects[0].scaleX;
                        t.forEachObject(function (obj) {
                            obj.width = w;
                            obj.scaleX = scaleX;
                        }, _this.ctx);
                        t.addWithUpdate(null);
                        _this.ctx.renderAll();
                    }
                }
            });
        },
        clone: function (t) {
            var _this = this;
            return _buildInput({
                input: _templates.form.clone,
                value: null,
                listener: {
                    action: 'click',
                    f: function () {
                        _clone.call(_this, t);

                        _this.ctx.renderAll();
                    }
                }
            });
        },
        lock: function (t) {
            var _this = this;
            return _buildInput({
                input: t.locked ? _templates.image_inputs.unlock : _templates.image_inputs.lock,
                listener: {
                    action: 'click',
                    f: function () {
                        if(!t.locked){
                            t.set({
                                borderColor: 'rgba(204,0,0, 0.3)',
                                cornerColor: 'rgba(204,0,0, 1)',
                            });
                        }else{
                           t.set({
                               borderColor: 'rgba(100, 100, 255, 0.3)',
                               cornerColor: 'rgba(100, 100, 255, 1)',
                               });
                        }
                        t.locked = !t.locked;
                        t.lockMovementX = !t.lockMovementX;
                        t.lockMovementY = !t.lockMovementY;
                        t.lockScalingX = !t.lockScalingX;
                        t.lockScalingY = !t.lockScalingY;
                        t.lockRotation = !t.lockRotation;
                        if (t.locked) {
                            $(this).html("<span class='fa fa-lock' title='Unlock'></span>")
                        } else {
                            $(this).html("<span class='fa fa-unlock' title='Lock'></span>")
                        }
                        
                        _this.render();
                    }
                }
            });
        },
        multilock: function (t) {
            var _this = this;
            var checkForButton;
            for(i =0; i<_this.ctx._activeGroup._objects.length; i++){
                if(_this.ctx._activeGroup._objects[i].locked == true){
                    //currently kind of broken, but once multiselect is fixed will sort itself out
                    t.set({
                        borderColor: 'rgba(204,0,0, 0.3)',
                        cornerColor: 'rgba(204,0,0, 1)',
                    });
                    checkForButton = true;
                    break;
                }
            }

            return _buildInput({
                input: checkForButton ? _templates.image_inputs.unlock : _templates.image_inputs.lock,
                listener: {
                    action: 'click',
                    f: function () {
                        var oneIsLocked;
                        singleObjects = _this.ctx._activeGroup._objects;
                        for(i =0; i<singleObjects.length; i++){
                            if(singleObjects[i].locked == true){
                                oneIsLocked = true;
                            }
                        }
                        if(oneIsLocked){
                            for(i =0; i<singleObjects.length; i++){
                                singleObjects[i].set({
                                    borderColor: 'rgba(100, 100, 255, 0.3)',
                                    cornerColor: 'rgba(100, 100, 255, 1)',
                                });
                                t.set({
                                    borderColor: 'rgba(100, 100, 255, 0.3)',
                                    cornerColor: 'rgba(100, 100, 255, 1)',
                                });

                                singleObjects[i].locked = false;
                                singleObjects[i].lockMovementX = false;
                                singleObjects[i].lockMovementY = false;
                                singleObjects[i].lockScalingX = false;
                                singleObjects[i].lockScalingY = false;
                                singleObjects[i].lockRotation = false;
                            }
                        }
                        else{
                            for(i =0; i<singleObjects.length; i++){
                                singleObjects[i].set({
                                    borderColor: 'rgba(204,0,0, 0.3)',
                                    cornerColor: 'rgba(204,0,0, 1)',
                                });
                                t.set({
                                    borderColor: 'rgba(204,0,0, 0.3)',
                                    cornerColor: 'rgba(204,0,0, 1)',
                                });
                                singleObjects[i].locked = true;
                                singleObjects[i].lockMovementX = true;
                                singleObjects[i].lockMovementY = true
                                singleObjects[i].lockScalingX = true;
                                singleObjects[i].lockScalingY = true;
                                singleObjects[i].lockRotation = true;
                            }
                        }

                        if (oneIsLocked) {
                            $(this).html("<span class='fa fa-unlock' title='Lock All'></span>")
                        } else {
                            $(this).html("<span class='fa fa-lock' title='Unlock All'></span>")
                        }
                        _this.render();
                    }
                }
            });
        },
        color: function (t, value, onchange) {
            var _this = this;
            var parent = _buildInput({
                input: _templates.text_inputs.text,
                label: "Color"
            });

            var input = parent.find('input').get(0);
            var c = new jscolor(input);
            if (value) {
                c.fromString(value);
            } else {
                c.fromString(t.getFill());
            }

            if (onchange) {
                input.onchange = onchange;
            } else {
                input.onchange = function () {
                    t.setFill("#" + this.value);
                    _this.render();
                };
            }

            return parent;
        }
    };

    function _buildInput(options) {
        var formGroup = $(_templates.form.group);
        var i = $(options.input);
        i.tooltip();
        if (options.value) {
            i.val(options.value);
        }

        if (options.label) {
            label = $(_templates.form.label);
            label.text(options.label);
            formGroup.append(label);
        }

        formGroup.append(i);

        if (options.listener) {
            i.on(options.listener.action, options.listener.f);
        }
        return formGroup;
    }

    function _editCircle(event) {
        var _this = this;
        _this.toolset.empty();

        var btnGroup = $('<div class="btn-group"></div>');
        var borderGroup = $('<div class="btn-group"></div>');
        var borderFormGroup =  $('<div class="form-group"></div>');

        var c = _inputFactory.color.call(this, event.target);

        var f = _inputFactory.forward.call(this, event.target);
        var b = _inputFactory.back.call(this, event.target);
        var cl = _inputFactory.clone.call(this, event.target);
        var l = _inputFactory.lock.call(this, event.target);
        var border = _inputFactory.addBorder.call(this, event.target);

        btnGroup.append(f, b, l, cl);
        borderGroup.append(border);
        borderFormGroup.append(borderGroup);
        _this.toolset.append(btnGroup,borderFormGroup, c);
    }
    function _editLine(event) {
        var _this = this;
        _this.toolset.empty();

        var btnGroup = $('<div class="btn-group"></div>');
        var borderGroup = $('<div class="btn-group"></div>');
        var column1 = $(_templates.form.column);
        var column2 = $(_templates.form.column);
        var column3 = $(_templates.form.column);

        var c = _inputFactory.color.call(this,
            event.target,
            event.target.getStroke(),
            function () {
                event.target.setStroke("#" + this.value);
                _this.render();
            }
        );

        var border = _inputFactory.addLineBorder.call(this, event.target);

        var f = _inputFactory.forward.call(this, event.target);
        var b = _inputFactory.back.call(this, event.target);
        var cl = _inputFactory.clone.call(this, event.target);
        var l = _inputFactory.lock.call(this, event.target);


        btnGroup.append(f, b, l, cl);
        borderGroup.append(border);

        column1.append(btnGroup);
        column2.append(borderGroup)
        column3.append(c);
        _this.toolset.append(column1, column2, column3);
    }
    function _editRect(event) {
        var _this = this;
        _this.toolset.empty();

        var btnGroup = $('<div class="btn-group"></div>');
        var borderGroup = $('<div class="btn-group"></div>');
        var borderFormGroup =  $('<div class="form-group"></div>');
        var radiusGroup = $('<div class="btn-group"></div>');

        var ry = _buildInput({
            input: _templates.text_inputs.number,
            label: "Corner Radius Y",
            value: event.target.ry * 10,
            listener: {
                action: 'input',
                f: function () {
                    event.target.set({ry: this.value / 10});
                    _this.render();
                }
            }
        });
        var rx = _buildInput({
            input: _templates.text_inputs.number,
            label: "Corner Radius X",
            value: event.target.rx * 10,
            listener: {
                action: 'input',
                f: function () {
                    event.target.set({rx: this.value / 10});
                    _this.render();
                }
            }
        });

        var r = _inputFactory.rotate.call(this, event.target);
        var c = _inputFactory.color.call(this, event.target);
        var f = _inputFactory.forward.call(this, event.target);
        var b = _inputFactory.back.call(this, event.target);
        var cl = _inputFactory.clone.call(this, event.target);
        var l = _inputFactory.lock.call(this, event.target);

        var border = _inputFactory.addBorder.call(this, event.target);

        btnGroup.append(f, b, l, cl, r);
        borderGroup.append(border);
        borderFormGroup.append(borderGroup);
        radiusGroup.append(rx, ry);
        _this.toolset.append(btnGroup, borderFormGroup, radiusGroup, c);
    }
    function _editImage(event) {
        var _this = this;
        _this.toolset.empty();

        var btnGroup = $('<div class="btn-group"></div>');
        var borderGroup = $('<div class="btn-group"></div>');
        var borderFormGroup =  $('<div class="form-group"></div>');

        var f = _buildInput({
            input: _templates.image_inputs.forward,
            listener: {
                action: 'click',
                f: function () {
                    event.target.bringForward();
                    _this.render();
                }
            }
        });

        var b = _buildInput({
            input: _templates.image_inputs.back,
            listener: {
                action: 'click',
                f: function () {
                    event.target.sendBackwards();
                    _this.render();
                }
            }
        });
        var border = _inputFactory.addBorder.call(this, event.target);
        var cl = _inputFactory.clone.call(this, event.target);
        var r = _inputFactory.rotate.call(this, event.target);
        var l = _inputFactory.lock.call(this, event.target);

        btnGroup.append(f, b, l, cl, r);
        borderGroup.append(border);
        borderFormGroup.append(borderGroup);
        _this.toolset.append(btnGroup, borderFormGroup);
    }

    function multiEditItem(event){
        var _this = this;

        var tabs = _inputFactory.tabs([
            {link: "#content", name: "Content"},
            {link: "#name", name: "Name"},
            {link: "#price", name: "Price"},
            {link: "#description", name: "Description"}
        ]);

        var contentTab = $("<div role='tabcontent' class='tab-pane active' id='content'></div>");
        var nameTab = $("<div role='tabcontent' class='tab-pane' id='name'></div>");
        var priceTab = $("<div role='tabcontent' class='tab-pane' id='price'></div>");
        var descriptionTab = $("<div role='tabcontent' class='tab-pane' id='description'></div>");

        var ns = _buildInput({
            input: _templates.text_inputs.number,
            value: event.target._objects[0]._objects[0].getFontSize(),
            label: "Name Font Size",
            listener: {
                action: 'input',
                f: function () {
                    for(i=0; i<event.target._objects.length; i++){
                        event.target._objects[i]._objects[0].setFontSize(this.value);
                    }
                    _this.render();
                }
            }
        });
        var ps = _buildInput({
            input: _templates.text_inputs.number,
            value: event.target._objects[0]._objects[2].getFontSize(),
            label: "Name Font Size",
            listener: {
                action: 'input',
                f: function () {
                    for(i=0; i<event.target._objects.length; i++){
                        event.target._objects[i]._objects[2].setFontSize(this.value);
                    }
                    _this.render();
                }
            }
        });
        var ds = _buildInput({
            input: _templates.text_inputs.number,
            value: event.target._objects[0]._objects[1].getFontSize(),
            label: "Name Font Size",
            listener: {
                action: 'input',
                f: function () {

                    for(i=0; i<event.target._objects.length; i++){
                        event.target._objects[i]._objects[1].setFontSize(this.value);
                        //event.target._objects[i]._objects.addWithUpdate();
                    }
                    _this.render();
                }
            }
        });

        var dColor = _inputFactory.color.call(this,
            event.target,
            event.target.fill,
            function () {
                for(i=0; i<event.target._objects.length; i++){
                    event.target._objects[i]._objects[1].setColor("#" + this.value);
                }
                _this.render();
            }
        );

        var nColor = _inputFactory.color.call(this,
            event.target,
            event.target.fill,
            function () {
                for(i=0; i<event.target._objects.length; i++){
                    event.target._objects[i]._objects[0].setColor("#" + this.value);
                }
                _this.render();
            }
        );

        var pColor = _inputFactory.color.call(this,
            event.target,
            event.target.fill,
            function () {
                for(i=0; i<event.target._objects.length; i++){
                    event.target._objects[i]._objects[2].setColor("#" + this.value);
                }
                _this.render();
            }
        );

        var dStyle = _inputFactory.multiMenuFontStyles.call(this, event.target, 'description');
        var nStyle = _inputFactory.multiMenuFontStyles.call(this, event.target, 'name');
        var pStyle = _inputFactory.multiMenuFontStyles.call(this, event.target, 'price');

        var dp = _buildInput({
            input: _templates.text_inputs.number,
            label: "Top Margin",
            value: Math.round(event.target._objects[0].descriptionEL.top * 2),
            listener: {
                action: 'input',
                f: function () {
                    for(i=0; i<event.target._objects.length; i++){
                        event.target._objects[i].descriptionEL.top = this.value / 2;
                    }
                    _this.render();
                }
            }
        });


        /*        var dw = _buildInput({
            input: _templates.text_inputs.number,
            label: "Description Width",
            value: Math.floor(event.target._objects[0].descriptionEL.width / 310 * 100),
            listener: {
                action: 'input',
                f: function () {
                    for(i=0; i<event.target._objects.length; i++){
                        event.target._objects[i].options.dW = this.value / 100;
                        event.target._objects[i].setCustomText(descriptionEl.getText().replace(/\n/g, ""));

                    }

                    _this.render();
                }
            }
        });*/

        var df = _inputFactory.multiMenuFontFamily.call(this, event.target,'description');
        var nf = _inputFactory.multiMenuFontFamily.call(this, event.target,'name');
        var pf = _inputFactory.multiMenuFontFamily.call(this, event.target, 'price');

        var nt = _buildInput({
            input: _templates.text_inputs.text,
            value: event.target._objects[0].nameEL.getText(),
            label: "Name",
            listener: {
                action: 'keyup',
                f: function () {
                    for(i=0; i<event.target._objects.length; i++){
                        event.target._objects[i].nameEL.text = this.value;
                    }

                    _this.render();
                }
            }
        });
        var pt = _buildInput({
            input: _templates.text_inputs.text,
            value: event.target._objects[0].priceEL.getText(),
            label: "Price",
            listener: {
                action: 'keyup',
                f: function () {
                    for(i=0; i<event.target._objects.length; i++){
                        event.target._objects[i].priceEL.text = this.value;
                    }


                    _this.render();
                }
            }
        });
        var dt = _buildInput({
            input: _templates.text_inputs.textarea,
            value: event.target._objects[0].descriptionEL.getText(),
            label: "Description",
            listener: {
                action: 'keyup',
                f: function () {
                    for(i=0; i<event.target._objects.length; i++){
                        event.target._objects[i].descriptionEL.text = this.value;
                    }
                    _this.render();
                }
            }
        });
        var allColor = _inputFactory.color.call(this,
            event.target,
            event.target.fill,
            function () {
                for(i=0; i<event.target._objects.length; i++){
                    event.target._objects[i].setColor("#" + this.value);
                }
                _this.render();
            }
        );


        descriptionTab.append(ds, dColor, dStyle,df,dp);
        priceTab.append(ps, pColor, pStyle,pf);
        nameTab.append(ns,nColor, nStyle,nf);
        contentTab.append(nt,pt,dt, allColor);
        var tabContent = $("<div class='tab-content'></div>");
        tabContent.append(contentTab, nameTab, priceTab, descriptionTab);
        _this.toolset.append(tabs, tabContent);
    }

    function _editItem(event) {
        var _this = this;
        _this.toolset.empty();

        var tabs = _inputFactory.tabs([
            {link: "#content", name: "Content"},
            {link: "#name", name: "Name"},
            {link: "#price", name: "Price"},
            {link: "#description", name: "Description"}
        ]);

        var contentTab = $("<div role='tabcontent' class='tab-pane active' id='content'></div>");
        var nameTab = $("<div role='tabcontent' class='tab-pane' id='name'></div>");
        var priceTab = $("<div role='tabcontent' class='tab-pane' id='price'></div>");
        var descriptionTab = $("<div role='tabcontent' class='tab-pane' id='description'></div>");
        var borderGroup = $('<div class="btn-group"></div>');
        var borderFormGroup =  $('<div class="form-group"></div>');

        var cl = _inputFactory.clone.call(this, event.target);
        var rot = _inputFactory.rotate.call(this, event.target);
        var l = _inputFactory.lock.call(this, event.target);
        var descriptionEl = event.target.descriptionEL;

        var dp = _buildInput({
            input: _templates.text_inputs.number,
            label: "Top Margin",
            value: Math.round(event.target.descriptionEL.top * 2),
            listener: {
                action: 'input',
                f: function () {
                    event.target.descriptionEL.top = this.value / 2;
                    event.target.addWithUpdate(null);
                    _this.render();
                }
            }
        });

        var dw = _buildInput({
            input: _templates.text_inputs.number,
            label: "Description Width",
            value: Math.floor(event.target.descriptionEL.width / 310 * 100),
            listener: {
                action: 'input',
                f: function () {
                    event.target.options.dW = this.value / 100;
                    event.target.setCustomText(descriptionEl.getText().replace(/\n/g, ""));
                    event.target.addWithUpdate(null);
                    _this.render();
                }
            }
        });

        var dt = _buildInput({
            input: _templates.text_inputs.textarea,
            value: event.target.descriptionEL.getText(),
            label: "Description",
            listener: {
                action: 'input',
                f: function () {
                    event.target.descriptionEL.text = this.value;
                    event.target.addWithUpdate(null);
                    _this.render();
                }
            }
        });

        var ds = _buildInput({
            input: _templates.text_inputs.number,
            value: Math.floor(event.target.descriptionEL.getFontSize() * event.target.scaleX),
            label: "Description Font Size",
            listener: {
                action: 'input',
                f: function () {
                    event.target.descriptionEL.setFontSize(this.value);
                    event.target.setCustomText(descriptionEl.getText().replace(/\n/g, ""));
                    event.target.addWithUpdate(null);
                    _this.render();
                }
            }
        });

        var dStyle = _inputFactory.fontStyles.call(this, event.target.descriptionEL);
        var dColor = _inputFactory.color.call(this, event.target.descriptionEL);

        var nt = _buildInput({
            input: _templates.text_inputs.text,
            value: event.target.nameEL.getText(),
            label: "Name",
            listener: {
                action: 'keyup',
                f: function () {
                    event.target.nameEL.text = this.value;
                    event.target.addWithUpdate();
                    _this.render();
                }
            }
        });
        var cColor = _inputFactory.color.call(this, event.target);

        var ns = _buildInput({
            input: _templates.text_inputs.number,
            value: event.target.nameEL.getFontSize(),
            label: "Name Font Size",
            listener: {
                action: 'input',
                f: function () {
                    event.target.nameEL.setFontSize(this.value);
                    event.target.addWithUpdate();
                    _this.render();
                }
            }
        });
        var nStyle = _inputFactory.fontStyles.call(this, event.target.nameEL);
        var nColor = _inputFactory.color.call(this, event.target.nameEL);

        var pt = _buildInput({
            input: _templates.text_inputs.text,
            value: event.target.priceEL.getText(),
            label: "Price",
            listener: {
                action: 'keyup',
                f: function () {
                    event.target.priceEL.text = this.value;
                    event.target.addWithUpdate();
                    _this.render();
                }
            }
        });

        var ps = _buildInput({
            input: _templates.text_inputs.number,
            value: event.target.priceEL.getFontSize(),
            label: "Price Font Size",
            listener: {
                action: 'input',
                f: function () {
                    event.target.priceEL.setFontSize(this.value);
                    _this.render();
                }
            }
        });
        var pStyle = _inputFactory.fontStyles.call(this, event.target.priceEL);
        var pColor = _inputFactory.color.call(this, event.target.priceEL);

        var df = _inputFactory.fontFamily.call(this, event.target.descriptionEL);
        var nf = _inputFactory.fontFamily.call(this, event.target.nameEL);
        var pf = _inputFactory.fontFamily.call(this, event.target.priceEL);

        var content = $('<div class="btn-group"></div>');
        var actions = $('<div class="btn-group"></div>');
        var nameBtnGroup1 = $('<div class="btn-group"></div>');
        var nameBtnGroup2 = $('<div class="form-group"></div>');
        var descBtnGroup1 = $('<div class="btn-group"></div>');
        var descBtnGroup2 = $('<div class="btn-group"></div>');
        var descBtnGroup3 = $('<div class="btn-group"></div>');
        var priceBtnGroup1 = $('<div class="btn-group"></div>');
        var priceBtnGroup2 = $('<div class="form-group"></div>');
        var border = _inputFactory.addBorder.call(this, event.target);
        //build the content
        content.append(nt, pt, dt,cColor);
        contentTab.append(content);

        //build the actions
        actions.append(cl, rot,l);

        //price
        priceBtnGroup1.append(ps, pColor, pf);
        priceBtnGroup2.append(pStyle);
        priceTab.append(priceBtnGroup1, priceBtnGroup2);

        //description
        descBtnGroup1.append(ds, dColor, df);
        descBtnGroup2.append(dw, dp);
        descBtnGroup2.append(dStyle);
        descriptionTab.append(descBtnGroup1, descBtnGroup2, descBtnGroup3);

        //name
        nameBtnGroup1.append(ns, nColor, nf);
        nameBtnGroup2.append(nStyle);
        nameTab.append(nameBtnGroup1, nameBtnGroup2);

        var tabContent = $("<div class='tab-content'></div>");
        tabContent.append(contentTab, nameTab, priceTab, descriptionTab);
        borderGroup.append(border);
        borderFormGroup.append(borderGroup);


        _this.toolset.append(actions, tabs, tabContent);
    }
    function checkForText(groupObjects) {
        for (i = 0; i < groupObjects.length; i++) {
            if(groupObjects[i].type != 'textbox')
            {
                return false;
            }
        }
        return true;
    }
    function checkForMenuItem(groupObjects) {
        for (i = 0; i < groupObjects.length; i++) {
            if(groupObjects[i].type != 'menuItem')
            {
                return false;
            }
        }
        return true;
    }
    function checkForImage(groupObjects) {
        for (i = 0; i < groupObjects.length; i++) {
            if(groupObjects[i].type != 'image')
            {
                return false;
            }
        }
        return true;
    }
    function checkForShape(groupObjects) {
        for (i = 0; i < groupObjects.length; i++) {
            if(groupObjects[i].type != 'rect' && groupObjects[i].type != 'circle' && groupObjects[i].type != 'line'  )
            {
                return false;
            }
        }
        return true;
    }




    function _editGroup(event) {
        var _this = this;
        _this.toolset.empty();

        var groupObjects = _this.ctx._activeGroup._objects;

        //only texts selected
        if(checkForText(groupObjects)){
            _this.toolset.append($("<h4>(Text Fields)</h4>"));
            var btnGroup = $('<div class="btn-group"></div>');
            var colorGroup = $('<div class="btn-group"></div>');
            var lockRotateGroup = $('<div class="btn-group"></div>');
            var formGroup = $('<div class="form-group"></div>');
            var formGroup2 = $('<div class="form-group"></div>');
            var formGroup3 = $('<div class="form-group"></div>');
            var ew = _inputFactory.equalWidths.call(this, event.target);
            var eh = _inputFactory.equalHeights.call(this, event.target);

            var vAlign = _inputFactory.ValignAll.call(this, event.target._objects);
            var hAlign = _inputFactory.HalignAll.call(this, event.target._objects);

            var ec = _inputFactory.color.call(this,
                event.target,
                event.target.fill,
                function () {
                    event.target.setColor("#" + this.value);
                    _this.render();
                }
            );

            var l = _inputFactory.multilock.call(this, event.target);
            var r = _inputFactory.rotate.call(this, event.target);
            var rip = _inputFactory.rotateInPlace.call(this, event.target);

            //text
            var t = _buildInput({
                input: _templates.text_inputs.text,
                value: event.target._objects[0].getText(),
                label: "Text",
                listener: {
                    action: 'keyup',
                    f: function () {
                        for(i=0; i<event.target._objects.length; i++) {
                            event.target._objects[i].setText(this.value);
                        }
                        _this.render();
                    }
                }
            });
            //font siZe
            var f = _buildInput({
                input: _templates.text_inputs.number,
                value: event.target._objects[0].getFontSize(),
                label: "Font Size",
                listener: {
                    action: 'input',
                    f: function () {
                        for(i=0; i<event.target._objects.length; i++){
                            event.target._objects[i].setFontSize(this.value);
                        }
                        _this.render();
                    }
                }
            });
            //bold/italic/strikethrough/underline/o
            var fs = _inputFactory.multiFontStyles.call(this, event.target);
            //font family
            var ff = _inputFactory.multiFontFamily.call(this, event.target);
            //alignment
            var fa = _inputFactory.multiTextAlign.call(this, event.target);

            btnGroup.append(ew, eh, vAlign, hAlign, l, r, rip);
            colorGroup.append(ec);
            formGroup.append(btnGroup);
            formGroup3.append(colorGroup);
            _this.toolset.append(formGroup,t, f,fs, ff, fa, formGroup3);
        }
        //only menu items selected
        else if(checkForMenuItem(groupObjects)){
            _this.toolset.append($("<h4>(Menu Items)</h4>"));
            var btnGroup = $('<div class="btn-group"></div>');
            var lockRotateGroup = $('<div class="btn-group"></div>');
            var formGroup = $('<div class="form-group"></div>');
            var formGroup2 = $('<div class="form-group"></div>');
            var ew = _inputFactory.equalWidths.call(this, event.target);
            var eh = _inputFactory.equalHeights.call(this, event.target);

            var l = _inputFactory.multilock.call(this, event.target);
            var r = _inputFactory.rotate.call(this, event.target);
            var rip = _inputFactory.rotateInPlace.call(this, event.target);

            var vAlign = _inputFactory.ValignAll.call(this, event.target._objects);
            var hAlign = _inputFactory.HalignAll.call(this, event.target._objects);

            btnGroup.append(ew, eh,vAlign,hAlign,l,r,rip);
            formGroup.append(btnGroup);
            _this.toolset.append(formGroup, formGroup2);

            multiEditItem.call(this, event);


        }
        //misc menu
        else{
            _this.toolset.append($("<h4>(Misc Items)</h4>"));
            var btnGroup = $('<div class="btn-group"></div>');
            var colorGroup = $('<div class="btn-group"></div>');
            var lockRotateGroup = $('<div class="btn-group"></div>');
            var alignGroup = $('<div class="btn-group"></div>');
            var formGroup = $('<div class="form-group"></div>');
            var formGroup1 = $('<div class="form-group"></div>');
            var formGroup2 = $('<div class="form-group"></div>');
            var formGroup3 = $('<div class="form-group"></div>');
            var ew = _inputFactory.equalWidths.call(this, event.target);
            var eh = _inputFactory.equalHeights.call(this, event.target);

            var ec = _inputFactory.color.call(this, event.target, event.target.fill, function () {
                    event.target.setColor("#" + this.value);
                    _this.render();
                });
            var l = _inputFactory.multilock.call(this, event.target);
            var r = _inputFactory.rotate.call(this, event.target);
            var rip = _inputFactory.rotateInPlace.call(this, event.target);
            var vAlign = _inputFactory.ValignAll.call(this, event.target._objects);
            var hAlign = _inputFactory.HalignAll.call(this, event.target._objects);


            btnGroup.append(ew, eh, vAlign, hAlign);
            colorGroup.append(ec);
            lockRotateGroup.append(l,r, rip);
            formGroup.append(btnGroup);
            formGroup2.append(lockRotateGroup);
            formGroup3.append(colorGroup);
            _this.toolset.append(formGroup,formGroup2, formGroup3);
        }

    }

    function _editTextBox(event) {

        var _this = this;
        _this.toolset.empty();

        var btnGroup = $('<div class="btn-group"></div>');
        var borderGroup = $('<div class="btn-group"></div>');
        var borderFormGroup =  $('<div class="form-group"></div>');

        var t = _buildInput({
            input: _templates.text_inputs.textarea,
            value: event.target.getText(),
            label: "Text",
            listener: {
                action: 'keyup',
                f: function () {
                    event.target.setText(this.value);
                    _this.render();
                }
            }
        });

        var f = _buildInput({
            input: _templates.text_inputs.number,
            value: event.target.getFontSize(),
            label: "Font Size",
            listener: {
                action: 'input',
                f: function () {
                    event.target.setFontSize(this.value);
                    _this.render();
                }
            }
        });

        var c = _inputFactory.color.call(this,
            event.target,
            event.target.fill,
            function () {
                event.target.setColor("#" + this.value);
                _this.render();
            }
        );
        var border = _inputFactory.addBorder.call(this, event.target);
        var r = _inputFactory.rotate.call(this, event.target);
        var cl = _inputFactory.clone.call(this, event.target);
        var l = _inputFactory.lock.call(this, event.target);
        var fs = _inputFactory.fontStyles.call(this, event.target);
        var ff = _inputFactory.fontFamily.call(this, event.target);
        var fa = _inputFactory.textAlign.call(this, event.target);

        btnGroup.append(l, cl, r);
        borderGroup.append(border);
        borderFormGroup.append(borderGroup);
        _this.toolset.append(btnGroup, borderFormGroup, t, f, c, fs, ff, fa);
    }

    var _templates = {
        shapes:{
            solidborder: '<button type="button" class="toolbar-btn"><span class="khamu-solid-border" title="Solid Border"></span></button>',
            dashedborder: '<button type="button" class="toolbar-btn"><span class="khamu-dashed-border" title="Dashed Border"></span></button>',
        },
        text_inputs: {
            text: "<input class='form-control' autocomplete=off type=text/>",
            number: "<input class='form-control' autocomplete=off type='number' />",
            bordernumber: "<input class='form-control' autocomplete=off type='number' min='0' />",
            textarea: "<textarea class='form-control' cols='45' style='height:200px;'></textarea>"
        },
        image_inputs: {
            font: '<button type="button" class="btn btn-warning"><span class="glyphicon glyphicon-pencil"></span> Font Style</button>',
            forward: '<button type="button" class="toolbar-btn" title="Move Forward"><span class="khamu-movetofront"></span></button>',
            back: '<button type="button" class="toolbar-btn" title="Move Back"><span class="khamu-movetoback" ></span></button>',
            lock: '<button type="button" class="toolbar-btn"><span class="fa fa-unlock" title="Lock"></span></button>',
            unlock: '<button type="button" class="toolbar-btn"><span class="fa fa-lock" title="Unlock"></span></button>',
            talign: '<button type="button" class="toolbar-btn"><span class="glyphicon glyphicon-object-align-top" title="Vertical Align"></span></button>',
            lalign: '<button type="button" class="toolbar-btn"><span class="glyphicon glyphicon-object-align-left" title="Horizontal Align"></span></button>'
        },
        form: {
            menuOption : '<input type="checkbox">',
            column: '<div class="col-md-12" style="margin-left: 0px; padding-left: 0px;"></div>',
            group: '<div class="form-group"></div>',
            btnGroup: '<div class="btn-group"></div>',
            label: '<label class=""></label>',
            equalWidth: '<button type="button" title="Equalize Width" class="toolbar-btn"><span class="khamu-equalizewidth"></span></button>',
            equalHeight: '<button type="button" title="Equalize Height" class="toolbar-btn"><span class="khamu-equalizeheight"></span></button>',
            clone: '<button type="button" class="toolbar-btn" title="Clone"><span class="glyphicon glyphicon-duplicate"></span></button>',
            rotate: '<button type="button" class="toolbar-btn" title="Rotate"><span class="glyphicon glyphicon-repeat"></span></button>',
            rotateInPlace: '<button type="button" class="toolbar-btn" title="Rotate In Place"><span class="glyphicon glyphicon-refresh"></span></button>',
            color: "<input type='color' class='jscolor' class='form-control'>",
            select: "<select class='form-control'></select>",
            option: "<option></option>",
            bold: "<input type='button' class='font-style-btn' value='B'>",
            italic: "<input type='button' class='font-style-btn' value='I'>",
            underline: "<input type='button' class='font-style-btn' value='U'>",
            overline: "<input type='button' class='font-style-btn' value='O'>",
            strikethrough: "<input type='button' class='font-style-btn' value='S'>",
            left_align: "<button type='button' class='font-style-btn' data-align='left'><span class='glyphicon glyphicon-align-left'></span></button>",
            center_align: "<button type='button' class='font-style-btn' data-align='center'><span class='glyphicon glyphicon-align-center'></span></button>",
            right_align: "<button type='button' class='font-style-btn' data-align='right'><span class='glyphicon glyphicon-align-right'></span></button>"
        }
    };
})();
