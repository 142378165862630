(function ($) {

    $.addRows = function (element, options ) {

        var plugin = this;

        $(this).data('addRows', plugin.settings);

        var $element = $(element),
            element = element;

        plugin.init = function() {
            plugin.settings = $.extend({
                // These are the defaults.
                column1: {
                    name: "Column 1",
                    textarea: false
                },
                column2: {
                    name: "Column 2",
                    textarea: false
                },
                allowSorting : false,
                values: [

                ]
            }, options || {});

            return $element.each(function() {
                var table = $(this).find("table");
                var add = $('<span class="glyphicon glyphicon-plus" style="float:right"></span>');

                $(this).prepend(add);

                var elementHtml = "<table class='rowAdd'><thead>"
                                +'<tr>'
                                +    '<th class="col-md-2">'+plugin.settings.column1.name+'</th>'
                                +    '<th class="col-md-2">'+plugin.settings.column2.name+'</th>'
                                +    '<th class="col-md-1"></th>'
                            +    '</tr>'
                        +    '</thead>'
                        +    '<tbody>'
                            +    '<tr></tr>'

                for(var i = 0; i < plugin.settings.values.length; i++) {
                    elementHtml += plugin.createRow({
                            col1: plugin.settings.values[i].column1,
                            col2: plugin.settings.values[i].column2
                    });
                }
                elementHtml += '</tbody></table>';

                $(this).append(elementHtml);
                var remove = $('.remove');

                if(plugin.settings.allowSorting == true) {
                    $('.down').on('click', function () {
                        var $current = $(this).closest('.addItem');
                        var $next = $current.next('.addItem').first();
                        if ($next.length !== 0) {
                            $current.insertAfter($next);
                        }
                        return false;
                    });

                    $('.up').on('click', function () {
                        var $current = $(this).closest('.addItem');
                        var $previous = $current.prev('.addItem').first();
                        if ($previous.length !== 0) {
                            $current.insertBefore($previous);
                        }
                        return false;
                    });
                }

                add.on('click', function () {
                    plugin.addSize();
                });

                remove.on('click', function () {
                    plugin.removeSize(this);
                });

            });
        };

        plugin.addSize = function() {
            $(element).find("tbody:last-child").append($(plugin.createRow()));
            $('.remove').on('click', function () {
                plugin.removeSize(this);
            });
        };

        plugin.removeSize = function(element) {
            $(element).closest('.addItem').remove();
        };

        plugin.createRow = function(obj) {
            var col1;
            var col2;
            var sorting;

            if(obj) {
                col1 = obj.col1;
                col2 = obj.col2;
            } else {
                col1 = "";
                col2 = "";
            }

            var settings = plugin.settings;

            var col1Input = '';
            if(settings.column1.textarea){
                col1Input = '<textarea class="form-control" name="'+settings.column1.name.toLowerCase()+'_1[]" >'+col1+'</textarea>';
            } else {
                col1Input = '<input type="text" class="form-control" name="'+settings.column1.name.toLowerCase()+'_1[]" value="'+col1+'">';
            }

            var col2Input = '';
            if(settings.column2.textarea){
                col2Input = '<textarea class="form-control" required name="'+settings.column2.name.toLowerCase()+'_2[]" >'+col2+'</textarea>';
            } else {
                col2Input = '<input type="text" class="form-control" required name="'+settings.column2.name.toLowerCase()+'_2[]" value="'+col2+'">';
            }

            if(settings.allowSorting == true) {
                sorting = '<span style="margin-right:5px;" class="glyphicon glyphicon-arrow-down down"></span>' +
                    '<span class="up glyphicon glyphicon-arrow-up"></span>';
            } else {
                sorting = "";
            }
            return '<tr class="addItem">'
                    + '<td class="col-xs-2">'
                        + col1Input
                    + '</td>'
                    + '<td class="col-xs-2">'
                        + col2Input
                    + '</td>'
                    + '<td class="col-xs-1">'
                        + '<span class="glyphicon glyphicon-trash remove" style="margin-right:20px;"></span>'
                        + sorting
                    + '</td>'
                    + '</tr>';
        };

        plugin.init();

    };

    $.fn.addRows = function(options) {
        return this.each(function() {
            if (undefined == $(this).data('addRows')) {
                var plugin = new $.addRows(this, options);
                $(this).data('addRows', plugin);
            }
        });
    };

    $.uploadImage = function (element, options) {

        var plugin = this;

        $(this).data('uploadImage', plugin.settings);

        var $element = $(element),
            element = element;

        plugin.init = function() {
            plugin.settings = $.extend({
                image: {
                    url: null,
                    name: ''
                },
                clearImage: null
                // These are the defaults.
            }, options || {});

            return $element.each(function() {
                var table = $(this).find("table");

                var elementHtml = '<div class="form-group background_color">'
                        +'<label class="col-md-3 col-xs-12 control-label">Upload background Image</label>'
                        +'<div class="col-md-5 col-xs-12">'
                            +'<div style="display:inline; margin-right:5px;">'
                                +'<button class="btn btn-primary" type="button" id="searchImage">'
                                +'<span class="glyphicon glyphicon-search"></span>'
                                +' Choose Image'
                                +'</button>'
                            +'</div>'
                            +'<div class="fileUpload btn btn-success">'
                                +'<span>Upload Image</span>'
                                +'<input type="file" class="upload valid" name="background_image" id="uploadBtn">'
                                +'<input type="hidden" name="current_background_image" id="current_background_image" value="'+ (plugin.settings.image.url ? plugin.settings.image.url : "") +'">'
                            +'</div>'
                            +'<div style="display: inline;margin-left: 5px;">'
                                +'<input disabled="disabled" placeholder="Choose File" id="uploadFile">'
                            +'</div>'

                        +'</div>'
                    +'</div>'
                        +'<div class="form-group">'
                            +'<label class="col-md-3 col-xs-12 control-label"></label>'
                            +'<div class="col-md-5 col-xs-12 thumbnail-img">';

                if(plugin.settings.image.url) {
                    elementHtml += '<div id="background_image">'
                        +'<img style="width:92px;"  src="'+plugin.settings.image.url+'" />'
                        +'<label>'+plugin.settings.image.name+'</label>'
                        +'</div>'
                        +'<div style="margin-top:5px;">'
                        +'<button type="button" class="btn btn-danger" id="clearImage">'
                        +'<span class="glyphicon glyphicon-remove"></span>'
                        +' Clear Image'
                        +'</button>'
                        +'</div>';
                }


                var closingDiv = '</div></div>'

                elementHtml += closingDiv;
                $(this).append(elementHtml);

                $('#clearImage').on('click', plugin.clearImage);
                $('#searchImage').on('click', plugin.searchImage);
            });
        };


        plugin.clearImage = function() {
            if($.isFunction(plugin.clearImage)){
                document.getElementById("uploadFile").value = null;
                plugin.settings.clearImage();
            }
        };

        plugin.searchImage = function() {
            plugin.urlobj = 'current_background_image';

            var height = screen.height * 0.7 ;
            var width = screen.width * 0.7 ;
            var iLeft = (screen.width - width) / 2 ;
            var iTop = (screen.height - height) / 2 ;
            var sOptions = "toolbar=no,status=no,resizable=yes,dependent=yes" ;
            sOptions += ",width=" + width ;
            sOptions += ",height=" + height ;
            sOptions += ",left=" + iLeft ;
            sOptions += ",top=" + iTop ;
            window.open( '/filemanager?exclusiveFolder=Images/', "BrowseWindow", sOptions ) ;
        };

        window.SetUrl = function( url, width, height, alt )
        {
            var img = $('#background_image img');
            var uploadField = $('#uploadFile');
            var imageName = document.getElementById('current_background_image');

            if(imageName) {
                imageName.value = url;
            }

            if(img) {
                img.attr('src', url);
            }

            if(uploadField) {
                uploadField.attr('placeholder', url);
            }
            oWindow = null;
        };

        plugin.init();

        document.getElementById("uploadBtn").onchange = function () {
            document.getElementById("uploadFile").value = this.value;
        };
    };

    $.fn.uploadImage = function (options) {
        return this.each(function() {
            if (undefined == $(this).data('uploadImage')) {
                var plugin = new $.uploadImage(this, options);
                $(this).data('uploadImage', plugin);
            }
        });
    }

}( jQuery ));